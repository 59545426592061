.ui-messages {
  /* padding: 1em !important; */
  margin: 0px !important;
  /* margin-bottom: 10px !important; */
  font-family: var(--font-family-1) !important;
}

.p-message {
  margin: 0 !important; 
}

.ui-messages.ui-messages-info {
  background-color: var(--color-5) !important;
  border: 0 none !important;
  color: var(--color-13) !important;
}

.ui-messages.ui-messages-info .ui-messages-icon {
  color: var(--color-13) !important;
}

.p-dialog {
  border-radius: 10px 10px 0px 0px !important;
  border: none !important;
  box-shadow: none !important;
}

.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.customConfirmDialogClass .p-dialog-header {
  background-color: var(--color-6) !important;
  padding: 0.5em 1em !important;

  .p-dialog-title {
    font-weight: 500 !important;
  }
}

.p-confirm-dialog .p-dialog-content {
  display: initial !important;
  /* justify-content: center; */
  text-align: center;
  /* height: 5em; */
  padding: 1.7rem;
  padding-bottom: 2rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 1rem;
  height: 1rem;
  color: white;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: inherit;
}

.p-dialog-titlebar, .p-dialog-header {
  border-radius: 10px 10px 0px 0px !important;
  background-color: var(--color-6) !important;
  color: white !important;
  font-family: var(--font-family-1) !important;
  font-weight: 700 !important;
  padding: 0.5em !important;
}

.p-dialog-titlebar-icon {
  color: white !important;
}

.p-panel-titlebar-icon {
  color: white !important;
}

.p-confirmdialog-icon {
  font-size: 2.0em !important;
  margin-right: 0px !important;
}

.p-confirm-dialog {
  /* font-size: 2.0em !important; */
  margin-right: 0px !important;
  width: 27em;
}

.p-dialog .p-dialog-header .p-dialog-title,
.p-dialog .p-dialog-header h3 {
  font-weight: 700 !important;
  font-size: 1em !important;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 1.3em !important;
  position: relative;
  top: 0.1em;
  color: var(--color-28) !important;
  margin-right: 0.5rem;
}


.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0 !important;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 15px;
  color: #5E5E5E;
}

.p-confirmdialog-message {
  font-family: var(--font-family-1) !important;
  font-size: 16px !important;
}

.p-dialog .p-dialog-footer {
  border-top: none !important;
  /* border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; */
}

.p-dialog-footer {
  border-radius: 0px 0px 10px 10px !important;
  background-color: var(--color-13) !important;
  color: #333333 !important;
  padding: 0.5em !important;
  margin: 0 !important;
  text-align: right !important;
  position: relative !important;
  top: -25px !important;
}

.p-confirmdialog.p-dialog .p-dialog-content {
  padding-top: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p-radiobutton-label {
  margin: 0 0 0 .3em !important;
  font-family: var(--font-family-1) !important;
  color: #212121 !important;
  font-size: 0.95rem !important;
}

.splr-dl .p-dialog-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mdlsintop .p-dialog-content {
  padding-top: 0px !important
}

.p-messages-info-global {
  background-color: var(--color-5);
  border: 0 none;
  color: #ffffff;
}

.p-dialog .p-dialog-titlebar {
  border: 0px solid !important;
}

.p-dialog-detalle-billete .p-dialog-header {
  background-color: var(--color-6) !important;
}