@use 'styles/globals/tooltip/tooltip';
@import url('styles/globals/btn/button-login.scss');
@import url('styles/globals/btn/btn.scss');
@import url('styles/globals/terminales/terminales.scss');
@import url('styles/globals/tables/table.scss');
@import url('styles/globals/tables/paginadorTable.scss');
@import url('styles/globals/font/fonts.scss');


@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

:root {
  --color-1: #DA291C;
  --color-2: #CC1515;
  --color-3: #C60D0E;
  --color-4: #47BA31;
  --color-5: #1E37B7;
  --color-6: #002D74;
  --color-7: #C7D6E9;
  --color-8: #6C757D;
  --color-9: #F2F2F2;
  --color-10: #00000045;
  --color-11: #F8B7BD;
  --color-12: #FFC600;
  --color-13: #FFFFFF;
  --color-14: #333333;
  --color-15: #D8D8D8;
  --color-16: #FF0000;
  --color-17: #0952C5;
  --color-18: #E90505;
  --color-19: #2076BD59;
  --color-20: #197B30;
  --color-21: #6A6A6A;
  --color-22: #1EA97C;
  --color-23: #991D14;
  --color-24: #8A8080;
  --color-25: #7D7A7A;
  --color-26: #002D7421;
  --color-27: #545454;
  --color-28: #6b6969;
  --color-29: #dcdcdc;
  --color-30: #e1181e;
  --color-31: #1a9746;
  --color-32: #8894d4;
  --color-33: #f3f2f3;
  --color-34: #777777;
  --color-35: #edf0f5;
  --color-36: #dee2e6;
  --color-37: #f0f3f4;
  --color-38: #666666;
  --color-39: #a6a6a6;
  --color-40: #eff6ff;
  --color-41: #5278e2;
  --color-42: #e6e6e6;
  --color-43: #8c8c8c;
  --color-43: #8da0c0;
  --color-45: #7F95B9;
  --color-46: #d0d0d0;
  --color-47: #47BA31;
  --color-48: #2076BD;
  --color-49: #F0F3f4;
  --color-50: #00458D;

  --font-size-1: 10px;
  --font-size-2: 11px;
  --font-size-3: 12px;
  --font-size-4: 13px;
  --font-size-5: 14px;
  --font-size-6: 16px;
  --font-size-7: 18px;

  --shadow-1: 0px 3px 6px;
  --shadow-2: 0px 3px 8px;
  --border-radius-1: 5px;
  --border-radius-2: 10px;
  --border-radius-3: 15px;
  --border-radius-4: 20px;
  --font-family-1: 'Roboto';
  --font-family-2: 'Montserrat';
  --animation-1: 200ms;
}


body {
  font-family: var(--font-family-2) !important;
  background-color: #f4f4f4;
  height: 100vh;
}

body .pi {
  font-size: 0.7em;
  font-weight: 700;
}

hr {
  border: none;
  border-bottom: 1px solid var(--color-15);
}

.desactivado {
  color: #a2a2a2;
}

.p-inputgroup-custom {
  > div {
      width: 100%;
  }
}

/* Estilos para autocomplete */
@import url('styles/globals/autocomplete/autocomplete.scss');
/* Estilos para dropdown */
@import url('styles/globals/dropdown/dropdown.scss');
/* Estilos para los calendar */
@import url('styles/globals/calendar/calendar.scss');
/* Estilos para p-checkbox */
@import url('styles/globals/checkbox/checkbox.scss');

@import url('styles/globals/radioButton/radioButton.scss');

/* Estilos para los inputs */
@import url('styles/globals/inputs/inputs.scss');

@import url('styles/globals/textArea/textArea.scss');

/* style para p-table */
@import url('styles/globals/tables/table.scss');
/* style paginador table */
@import url('styles/globals/tables/paginadorTable.scss');
/* encabezados de panel */
@import url('styles/globals/panel/panel.scss');
/* para los messages de la app */
@import url('styles/globals/messages/messages.scss');
@import url('styles/globals/toast/toast.scss');
/* font-size */
/* font family para admin free */
@import url('styles/globals/font/fontSize.scss');
/*iconos*/
@import url('styles/globals/iconos/iconos.scss');
/*styles globales generales*/
@import url('styles/globals/estilos/estilosGlobales.scss');
/* Multiselect estilos generales*/
@import url('styles/globals/multiselect/multiselect.scss');
/* Steps estilos generales*/
@import url('styles/globals/steps/steps.scss');
/* Colores generales*/
@import url('styles/globals/colors/colors.scss');
@import url('styles/globals/tabview/tabview.scss');
@import url('styles/globals/silder/slider.scss');



//* nuevos estilos 
@import 'styles/newStyles/varColor.scss';
@import 'styles/newStyles/inputElements.scss';
@import 'styles/newStyles/grids.scss';