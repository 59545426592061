.p-slider .p-slider-range {
    background: var(--color-6);
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: var(--color-6);
    border-color: var(--color-6);
}

.p-slider .p-slider-handle:focus {
    box-shadow: none;
}