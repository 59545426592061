/* Responsive mobile */
@media screen and (max-width: 1118px) {
    :root {
        --maxElement: 300px;
    } 
}

/* responsive Destop */
@media screen and (min-width: 1118px) {
    :root {
        --maxElement: 396px;
    }
}

.grid-form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--maxElement), 1fr));
}

.full-width {
    grid-column: 1 / -1;
}
