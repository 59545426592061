.p-inputtextarea {
    background: #F0F3F4;
    border: none;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    outline: medium none;
    font-size: 14px;
    font-family: var(--font-family-1);
}

.p-inputtextarea:enabled:focus {
    box-shadow: none;
}