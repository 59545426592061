.multiselect-app {
    border-radius: 8px !important;
    border: 0px !important;
    background: var(--color-9) !important;
    height: 32px !important;
    font-size: 14px !important;
}

.p-multiselect .p-multiselect-label-container{
    font-family: 'Open Sans', sans-serif;
}

.p-multiselect {
    border-radius: 15px;
    background-color: #fff;
    padding-left: 10px;
    border: 0px;
    outline: medium none;
    font-family: var(--font-family-1) !important;
}

.p-multiselect-chip .p-multiselect-token {
    padding: 0rem 1rem !important;
    margin-right: 3px !important;
    background: var(--color-9) !important;
    color: #002D74 !important;
    border-color: #002D74!important;
    border: solid;
    border-width: 1px;
    border-radius: 7px !important;
}

.p-multiselect-chip .p-multiselect-token .pi-times-circle:before {
    content: "\e90b";
}