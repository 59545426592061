.color-rojo {
    color: var(--color-1);
}

.color-verde {
    color: var(--color-22);
}

.color-blanco{
    color: var(--color-13);
}

.bg-gris {
    background-color: var(--color-8);
}

.bg-rojo-claro {
    background-color: var(--color-1);
}

.bg-azul {
    background-color: var(--color-6);
}

.bg-rojo-oscuro {
    background-color: var(--color-23);
}

.color-azul{
    color: var(--color-6);
}

.color-gris{
    color: var(--color-8);
}

.color-gris-oscuro{
    color: var(--color-14);
}

.bg-azul-claro {
    background-color: var(--color-17);
}

.bg-gris-claro {
    background-color: var(--color-15);
}

.bg-azul-claro-2 {
    background-color: var(--color-7);
}

.color-gris-claro{
    color: var(--color-24);
}

.bg-yellow {
    background-color: var(--color-12);
}

.color-amarillo{
    color: var(--color-12);
}