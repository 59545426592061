.p-toast {
    opacity: 1;
}

.p-toast .p-toast-message.p-toast-message-success,
.p-toast .p-toast-message.p-toast-message-error {
    border-radius: 15px;
}

.p-toast .p-toast-message.p-toast-message-error {
    background: var(--color-3);
}

.p-toast .p-toast-message.p-toast-message-error .pi-times-circle:before {
    content: "\e90b";
}

.p-toast .p-toast-message.p-toast-message-success {
    background: var(--color-47);
}

.p-toast .p-toast-message.p-toast-message-error,
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close,
.p-toast .p-toast-message.p-toast-message-success,
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: white;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon {
    font-size: 1.25rem;
}


.p-toast .p-toast-message .p-toast-icon-close:focus {
    box-shadow:none;
}