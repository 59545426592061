.btn-login {
    box-shadow: var(--shadow-1) rgba(183, 20, 20, 0.45) !important;
    border-radius: 15px !important;
    background: var(--color-18) !important;
    color: #f1f1f1 !important;
    border: 0px !important;
}
.btn-login2 {
    box-shadow: var(--shadow-1) rgba(183, 20, 20, 0.45) !important;
    border-radius: 15px !important;
    background: var(--color-18) !important;
    color: #f1f1f1 !important;
    border: 0px !important;
}

.btn-login:hover {
    opacity: 0.95;
}

