.btn-round-table {
    border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    width: 2.2em !important;
    height: 2.2em !important;
    padding: .22rem .45rem !important;
}

.btn-round-del {
    background-color: #d68b94 !important;
    color: #212121 !important;
}

.btn-pup {
    background-color: #3F51B5 !important;
    color: #ffffff !important;
}

.btn-pup:hover {
    background-color: rgb(50, 64, 146) !important;
    color: #ffffff !important;
}

.btn-round-edit {
    background-color: var(--color-5) !important;
    color: #ffffff !important;
}

.btn-round-edit:hover {
    background-color: var(--color-5) !important;
    color: #ffffff !important;
}

.btn-round-del:hover {
    background-color: #c47d86 !important;
    color: #212121 !important;
}

.btn-add {
    background-color: #138496 !important;
    border-color: #117a8b !important;
}

.btn-add:hover {
    background-color: #0d96ac !important;
}

.mdl-btn-h {
    padding: 2px !important;
}

.btn-light-dark {
    box-shadow: 0 3.5px 4px 0 rgba(20, 157, 183, 0.45);
    border-radius: 15px;
    background-color: var(--color-5);
    padding: 8px 12px 8px 25px;
    color: #f1f1f1;
    padding: .30rem .8rem;
    border: 0px;
    outline: 0px !important;
    font-family: var(--font-family-1) !important;
}

.btn-light-red {
    box-shadow: 0 3.5px 4px 0 #c60d0e;
    border-radius: 15px;
    background-color: var(--color-3);
    padding: 8px 12px 8px 25px;
    color: #f1f1f1;
    padding: .30rem .8rem;
    border: 0px;
    outline: 0px !important;
    font-family: var(--font-family-1) !important;
}

.btn-light-gray {
    box-shadow: 0 3.5px 4px 0 rgba(20, 157, 183, 0.45);
    border-radius: 15px;
    background-color: var(--color-15);
    padding: 8px 12px 8px 25px;
    color: var(--color-8);
    padding: .30rem .8rem;
    border: 0px;
    outline: 0px !important;
    font-family: var(--font-family-1) !important;
}

.btn-light-dark:hover {
    background-color: #1cb4cf;
}

.btn-light {
    box-shadow: 0 3.5px 4px 0 rgba(20, 157, 183, 0.45);
    border-radius: 15px;
    background-color: var(--color-17) !important;
    color: #f1f1f1;
    padding: .30rem .8rem;
    border: 0px;
    outline: 0px !important;
    font-family: var(--font-family-1) !important;
}

.btn-light:hover {
    background-color: rgb(62, 136, 197) !important;
}

.btn-light.disabled,
.btn-light:disabled,
.btn-light-dark:disabled,
.btn-less:disabled,
.btn-warning-app:disabled,
.btn-delete:disabled,
.btn-light-client:disabled,
.p-disabled,
app-button .p-component:disabled {
    opacity: .50 !important;
    pointer-events: none !important;
}

.btn-less {
    box-shadow: 0 3.5px 4px 0 rgba(167, 187, 190, 0.45);
    border-radius: 15px;
    background-color: #6c757d;
    color: #f1f1f1;
    padding: .30rem .8rem;
    border: 0px;
    outline: 0px !important;
    font-family: var(--font-family-1) !important;
}

.btn-less:hover {
    background-color: #50585f;
}

.btn-delete {
    box-shadow: 0 3.5px 4px 0 rgb(247, 105, 107);
    border-radius: 15px;
    background-color: #DD4345;
    color: #f1f1f1;
    padding: .30rem .8rem;
    border: 0px;
    outline: 0px !important;
    font-family: var(--font-family-1) !important;
}

.btn-delete:hover {
    background-color: rgb(196, 51, 53);
}

.btn-warning-app {
    box-shadow: 0px 3px 6px #F3920063;
    border-radius: 15px;
    background-color: #F39200;
    color: #f1f1f1;
    padding: .30rem .8rem;
    border: 0px;
    outline: 0px !important;
    font-family: var(--font-family-1) !important;
}

.btn-warning-app:hover {
    background-color: rgb(194, 118, 4);
}

.btn-light-client {
    box-shadow: 0 3.5px 4px 0 rgba(20, 157, 183, 0.45);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: var(--color-17) !important;
    color: #f1f1f1;
    padding: .30rem .0rem;
    border: 0px;
    outline: 0px !important;
    font-family: var(--font-family-1) !important;
    width: 35px;
}

.btn-light-client:hover {
    background-color: rgb(62, 136, 197) !important;
}



.button-global {
    border-radius: 25px;
    border: none;
    outline: none;
    padding: 8px 12px 8px 25px;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s;
    box-shadow: var(--shadow-1) var(--color-10);
}

.button-global-with-icon {
    border-radius: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s;
    box-shadow: var(--shadow-1) var(--color-10);
}

.button-global-with-icon:hover,
.button-global:hover {
    opacity: 0.88;
}

.button-global-with-icon label,
.button-global label {
    font-weight: bolder;
    font-family: var(--font-family-2);
    margin-right: 15px;
}

.button-global label,
.button-global em {
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.button-global.button-volver {
    background: var(--color-7) !important;
}

.button-global.button-show-filter {
    background: var(--color-7) !important;
}

.button-global.button-add-red {
    background: var(--color-1) !important;
}

.button-global.button-clean-filters {
    background: var(--color-15) !important;
}

.button-global.button-init-filters {
    background: var(--color-6) !important;
}

.button-global.button-regresar {
    background: var(--color-17) !important;
}

.button-global.button-add-blue {
    background: var(--color-5) !important;
}

button {
    cursor: pointer;
}

.button-control {
    padding: 0.6rem 1rem;
    border-radius: 25px;
    border: 0px solid;
    cursor: pointer;
    transition: 0.3s;
}

.button-control:hover {
    opacity: 0.88;
}

.button-control label {
    font-size: 14px;
    font-family: var(--font-family-1);
    font-weight: bold;
    cursor: pointer;
}

.p-button-icon-only {
    width: 2.357rem;
    height: 2rem;
    padding: 0px 8px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 8px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 8px !important;
    background-color: #002d74;
}

.p-button-bedge {
    background-color: #002d74 !important;
    border-radius: 20px !important;
    margin-top: 20px;
}

.btn-custom-enabled {
    cursor: pointer;
    opacity: 1;
}

.btn-custom-disabled {
    cursor: default;
    opacity: .5;
}

.btn-outlined-moded {
    color: var(--color-6) !important;
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px;

    .pi {
        font-size: 12px;
    }
}

.btn-outlined-moded:focus {
    box-shadow: none;
}