.autocomplete-app {
    background-color: #f2f2f2 !important;
    border-radius: 15px !important;
    border: 0px !important;
    font-family: var(--font-family-1) !important;
    font-size: 15px !important;
    color: #66676A !important;
    box-shadow: 0 0 0 0.2em #fff !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    width: 100% !important;
  }