/* circulo redondo btns table */
.color-info {
    color: #555a63 !important;
}

.w-10 {
    width: 10% !important
}

.w-15 {
    width: 15% !important
}

.w-20 {
    width: 20% !important
}

.w-25 {
    width: 25% !important
}

.w-30 {
    width: 30% !important
}

.w-40 {
    width: 40% !important
}

.w-65 {
    width: 65% !important
}

.w-70 {
    width: 70% !important
}

.w-80 {
    width: 80% !important
}

.w-85 {
    width: 85% !important
}

.w-50 {
    width: 80% !important
}

.w-50-por {
    width: 50% !important
}

.w-90 {
    width: 90% !important
}

.w-95 {
    width: 95% !important
}

.w-100-px {
    width: 100px !important
}

.w-150-px {
    width: 150px !important
}

.w-120-px {
    width: 120px !important
}

.w-130-px {
    width: 130px !important
}

.w-140-px {
    width: 140px !important
}

.w-180-px {
    width: 180px !important
}

.w-185-px {
    width: 185px !important
}

.w-80-px {
    width: 80px !important
}

.w-70-px {
    width: 70px !important
}

.w-35-px {
    width: 35px !important
}

.sub-title-page {
    font-family: var(--font-family-1) !important;
    font-size: 1rem;
    margin: 0px !important;
}



.cir-red {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    color: #212121;
    background-color: #d68b94;
    width: 22px;
    height: 22px;
    text-align: center;
}

.circle-download {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    background-color: #3F51B5 !important;
    color: #ffffff !important;
    width: 30px;
    height: 30px;
    text-align: center;
}

.del-round-min {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    color: #212121;
    background-color: #d68b94;
    width: 22px;
    height: 22px;
    text-align: center;
    cursor: pointer;
    font-size: 14px !important
}

.del-round-min:hover {
    background-color: #c47d86 !important;
    color: #212121 !important;
}

.del-round-min-pi {
    line-height: 1.20 !important;
}

.edit-round-min {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    color: white;
    background-color: var(--color-5);
    width: 22px;
    height: 22px;
    text-align: center;
    cursor: pointer;
    font-size: 14px !important
}

.edit-round-min:hover {
    background-color: #0762a8;
}

.st {
    font-size: 0.98rem;
    font-family: var(--font-family-1) !important;
}

.mx-dtl-mdl {
    max-width: 800px;
    min-width: 800px;
}

.mx-sub-mdl {
    max-width: 850px;
    min-width: 850px;
}

.round-up-down {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    background-color: #f5a96d;
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}

.round-up-down:hover {
    background-color: #e99657;
}

.round-up-down-i {
    font-size: 28px !important;
    margin-top: 5px !important;
}

.outl-no {
    outline: medium none !important
}

.clr-activo {
    color: #2E7D32 !important;
}

.clr-anulado {
    color: #D84315 !important;
}



.i-slight {
    color: #808991 !important;
}

.copy {
    cursor: pointer;
}

.copy:hover {
    text-decoration: underline;
    color: #3b3b36;
}



.bg-cg {
    background-color: #f4f4f4;
    border-top: 1px solid #c8c8c8;
}



.p-2rem {
    padding: .2rem !important;
}

.mayuscula {
    text-transform: uppercase;
}

.steps-st {
    font-family: var(--font-family-1) !important;
    font-weight: 700;
    font-size: 15px;
}

.steps-circle {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    color: white;
    background-color: var(--color-5);
    width: 22px;
    height: 22px;
    text-align: center;
    line-height: 21px;
}

.signostamanio {
    width: 60px;
    height: 60px;
}

.clr-white {
    color: #ffff;
}

.multi-horario .p-multiselect-label-container .p-multiselect-label,
body .p-multiselect.multi-horario .p-multiselect-trigger {
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 100px;
}

.color-1 {
    color: var(--color-1);
}

.color-2 {
    color: var(--color-2);
}

.color-3 {
    color: var(--color-3);
}

.color-4 {
    color: var(--color-4);
}

.color-5 {
    color: var(--color-5);
}

.color-6 {
    color: var(--color-6);
}

.color-7 {
    color: var(--color-7);
}

.color-8 {
    color: var(--color-8);
}

.color-9 {
    color: var(--color-9);
}

.color-10 {
    color: var(--color-10);
}

.color-11 {
    color: var(--color-11);
}

.color-13 {
    color: var(--color-13);
}

.color-14 {
    color: var(--color-14);
}

.color-15 {
    color: var(--color-15);
}

.color-20 {
    color: var(--color-20);
}



.estado-ACTIVO {
    background: var(--color-20);
    border-radius: var(--border-radius-1);
    color: var(--color-9);
    display: block;
    font-weight: 600;
    padding: 1px 9px;
    text-transform: uppercase;
    text-align: center;
    width: 75px;
}

.estado-INACTIVO {
    background: var(--color-2);
    border-radius: var(--border-radius-1);
    color: var(--color-9);
    display: block;
    font-weight: 600;
    padding: 1px 9px;
    text-transform: uppercase;
    text-align: center;
    width: 75px;
}

.p-disabled,
.p-component:disabled {
    opacity: 0.8 !important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.w-100 {
    width: 100%;
}

.input-error input,
input.input-error,
.input-error .p-multiselect,
.input-error .p-dropdown,
.input-error .p-inputswitch .p-inputswitch-slider {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(169, 68, 66, .6);
}

.bg-color-20{
    background-color: var(--color-20);
}

.bg-color-2{
    background-color: var(--color-2);
}

.barra-estandar {
    border-bottom: solid 1px var(--color-36);
}

.bg-yellow-state {
    background-color: #ffd802 !important;
}