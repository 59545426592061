.white-tooltip {

  &.p-tooltip {
    margin-top: 1rem;
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
  }
  
  &.p-tooltip .p-tooltip-text {
    background: var(--color-13);
    color: var(--color-14);
    font-family: var(--font-family-1) !important;
    width: 30rem;
    padding: 1rem 2rem;
    box-shadow: none;
    border-radius: 1rem;
  }
  
  &.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    --size: 1em;
    border-bottom-color: var(--color-13);
    border-width: 0 var(--size) var(--size);
    left: calc(50% - var(--size));
    top: -15%;
  }

}


  