.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: var(--color-6);
    background: var(--color-6);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: transparent;
}