.tbl-app .p-paginator {
  background-color: #fff !important;
  border: 0px;
}

.p-paginator .p-dropdown {
  border: 0px;
  border-color: var(--color-15) !important;
  border: solid;
  border-radius: 10px;
  border-width: 1px;
}

.tbl-app .p-paginator .p-dropdown-trigger {
  background-color: #fff !important;
}

.tbl-app .p-paginator .p-dropdown .p-dropdown-label {
  background-color: #fff !important;
  color: var(--color-14) !important;
}

.tbl-app .p-paginator .p-paginator-current {
  font-family: var(--font-family-1) !important;
  color: var(--color-14) !important;
}

.tbl-app .p-paginator .p-dropdown {
  box-shadow: 0 0 0 0.2em #fff !important;
}

.p-datatable .p-datatable-tbody .p-state-highlight {
  background-color: #BF3A42 !important;
}

.p-paginator-page.p-active {
  background-color: var(--color-6) !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  min-width: 2rem;
  height: 2rem;
}

.p-paginator-first,
.p-paginator-prev,
.p-paginator-next,
.p-paginator-last {
  color: var(--color-6) !important;
}

.p-paginator .p-paginator-pages .p-paginator-page:focus {
  box-shadow: 0 0 0 0.2em rgb(252, 123, 159) !important;
}

.tbl .p-paginator {
  background-color: #fff;
  border: 0px;
}

.tbl .p-paginator .p-dropdown-trigger {
  background-color: #fff !important;
}

.tbl .p-paginator .p-dropdown .p-dropdown-label {
  background-color: #fff !important;
}

.tbl .p-paginator .p-paginator-current {
  font-family: var(--font-family-1) !important;
}

.tbl .p-paginator .p-dropdown {
  box-shadow: 0 0 0 0.2em #fff !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--color-6) !important;
  border-color: var(--color-6) !important;
}

.pagination-container-tbl-app-v3 {
  display: flex;
  justify-content: space-between;

  .paginator-container {
    display: flex;
    align-items: center;

    .p-paginator-pages .p-paginator-page {   
      font-size: 14px;
    }
  }

  .paginator-container-XXL {
    .p-paginator-pages {
      gap: 0.5rem;
      display: flex;
    }

    .p-paginator-pages .p-paginator-page {
      padding: .5rem;
      border-radius: 1rem !important;
    }
  }

  .p-paginator {
    background: #ffffff;
    color: #333333;
    border: solid #ffffff;
    border-width: 1px;
    padding: 0;
    border-radius: 3px;

    .p-paginator-pages .p-paginator-page:focus,
    .p-link:focus {
      box-shadow: none !important;
    }
  }
}

.pagination-container-tbl-app-v3 .dropdown-container {
  display: flex;
  align-items: center;
  color: var(--color-38);

  .p-dropdown {
    border-radius: 7px;
    padding-right: 0.25rem;
    border: 1px solid var(--color-39);

    .p-dropdown-label {
      font-size: 0.875rem;
      color: var(--color-38);
    }

    .p-dropdown-trigger {
      color: var(--color-38);
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      width: 1rem;

      .p-icon {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    border: 1px solid var(--color-39);
  }
}

.tbl-app-v4 .p-paginator-bottom > :nth-child(n):not(p-dropdown) {
  order: 1;
}

.tbl-app-v4 .p-paginator-bottom > p-dropdown {
  margin-right: auto;
}

.tbl-app-v4 .p-paginator {
  border: 0px !important;
  background: none !important;
  padding-top: 1rem !important;
}