.input-elements {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    padding: 0.5rem;
}

.input-elements > label {
    font-size: 14px;
    font-weight: 400;
    color: $black;
}

.required {
    position: relative;
}

.required:after {
    content: "*";
    padding-left: 0.2rem;
    color: $normalPrimary;
}

.input-style {
    width: 100%;
    padding: 10px;
    background:  $backgroundInputsGray;
    color: $normalGray;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 14px;
}

.content-element {
    width: 100%;
    padding-right: 5px;
}

.input-elements .p-radiobutton .p-radiobutton-box.p-highlight {
    background: $normalSecondary;
    border-color: $normalSecondary;
}