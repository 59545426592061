.icon-round-table {
    margin-left: -.02em !important;
    line-height: 1.23 !important;
}

.icon-round-edit {
    margin-left: -.2em !important;
    line-height: 0.9 !important;
}

.icon-round-table-2 {
    margin-left: -.13em !important;
    line-height: 1.1 !important;
}

.icon-round-del {
    margin-left: -.13em !important;
    font-size: 20px !important;
}

.icono-acciones {
    font-size: 25px;
    cursor: pointer;
}

.icono-acciones:hover {
    opacity: 0.9;
}

.trash {
    color: #E51111;
    font-size: 25px;
    padding-left: 12px;
    cursor: pointer;
}

.trash:hover {
    color: rgb(158, 35, 35);
}