.font-size-9 {
  font-size: 9px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-23 {
  font-size: 23px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-35 {
  font-size: 35px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.btn-size-admin {
  font-family: var(--font-family-1) !important;
  margin: 0px !important;
  cursor: pointer;
}

.font-new-roman {
  font-family: var(--font-family-1) !important;
}