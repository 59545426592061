.calendar-app {
  border-radius: 8px !important;
  border: 0px !important;
  width: 100% !important;
  padding-left: 8px !important;
  background-color: #f0f3f4 !important;
}

.calendar-app:focus {
  box-shadow: 0 0 0px !important;
}

.calendar-app-sm {
  border-radius: 8px !important;
  border: 0px !important;
  width: 100% !important;
  height: 32px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: #f0f3f4 !important;
  font-size: 14px;
}

.calendar-app-sm-icon {
  border-radius: 8px !important;
  border: 0px !important;
  width: 90% !important;
  height: 32px !important;
  background-color: #f0f3f4 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 0px !important;
  font-size: 14px;
}
.calendar-app-sx-icon {
  border-radius: 8px !important;
  border: 0px !important;
  width: 86% !important;
  height: 32px !important;
  background-color: #f0f3f4 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 0px !important;
  font-size: 14px;
}

.calendar-app-icon {
  border-radius: 8px !important;
  border: 0px !important;
  width: 60% !important;
  height: 32px !important;
  margin-top: 10px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: #f0f3f4 !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 0px !important;
}

.calendar-app-icon-size {
  border-radius: 8px !important;
  border: 0px !important;
  width: 85% !important;
  height: 32px !important;
  background-color: #f0f3f4 !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 0px !important;
}

.calendar-app-sm:focus {
  box-shadow: 0 0 0px !important;
}

.calendar-app-sm-pl {
  border-radius: 8px !important;
  border: 0px !important;
  width: 100% !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  background-color: #f0f3f4 !important;
}

.calendar-app-sm-pl:focus {
  box-shadow: 0 0 0px !important;
}

.bg-clr {
  background-color: #f8f9fa !important;
}

.p-calendar {
  display: block !important;
}

.p-calendar .p-widget:disabled {
  opacity: 1 !important;
}

.p-calendar .p-component:disabled {
  opacity: 1 !important;
}

.p-datepicker tbody td {
  padding: 0.25em 0.325em !important;
  box-sizing: border-box !important;
}

.p-datepicker .p-button {
  padding: 0.25em 1em !important;
  font-family: var(--font-family-1) !important;
  color: #333;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
}

.p-datepicker .p-button:enabled:hover {
  background-color: #c8c8c8;
  color: #333;
  border-color: #c8c8c8;
}

.p-datepicker .p-button:enabled:active {
  background-color: #a0a0a0;
  color: #333;
  border-color: #a0a0a0;
}

.p-datepicker .p-button:focus {
  box-shadow: 0 0 0 0.07rem #333;
}

.p-datepicker {
  min-width: auto !important;
  padding: 0 !important;
}

.p-datepicker table {
  font-size: 12px !important;
  margin: 0px !important;
}

.p-datepicker:not(.p-disabled)
  table
  td
  a:not(.p-active):not(.p-highlight):hover {
  background-color: #e8e8e8 !important;
  color: #000000 !important;
}

.p-datepicker tbody td a,
.p-datepicker-calendar tbody tr td span {
  padding: 0.25em !important;
  margin: 0 !important;
  text-align: center !important;
  color: #212121 !important;
  display: inline-block !important;
  height: 2.25em !important;
  width: 2.25em !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
  line-height: 1.8em !important;
}

.p-datepicker tbody td a.p-active,
.p-datepicker tbody td span.p-highlight {
  background-color: #7cb342 !important;
  color: #ffffff !important;
}

.p-datepicker thead tr {
  color: #ffffff !important;
  background: #607d8b !important;
}

.p-datepicker-title button {
  color: #ffffff !important;
}

.p-datepicker th {
  padding: 0.5em !important;
  text-align: center !important;
  font-weight: 700 !important;
  border: 0 !important;
}

.p-datepicker .p-datepicker-header {
  padding: 0.625em 1em !important;
  font-size: 1em !important;
  background: #455a64 !important;
  border-color: #455a64 !important;
  color: #fff !important;
}

.p-datepicker-next {
  color: #fff !important;
}

.p-datepicker-prev {
  color: #fff !important;
}

.p-datepicker-buttonbar {
  padding: 0.1em !important;
}

.p-datepicker select.p-datepicker-month {
  border-width: 0 !important;
  padding-bottom: 0px !important;
  padding-top: 0.25em !important;
  font-size: 1em !important;
  outline: medium none !important;
  appearance: none !important;
  background-color: #455a64 !important;
  color: #fff !important;
  padding-right: 15px !important;
  padding-left: 8px !important;
  cursor: pointer !important;
}

.p-datepicker select.p-datepicker-year {
  border-width: 0 !important;
  padding-bottom: 0px !important;
  padding-top: 0.25em !important;
  font-size: 1em !important;
  outline: medium none !important;
  appearance: none !important;
  background-color: #455a64 !important;
  color: #fff !important;
  padding-right: 15px !important;
  padding-left: 8px !important;
  cursor: pointer !important;
}

.ad-calendar-s:focus {
  box-shadow: 0 0 0px !important;
}

.calendar-app-moded {
  .p-datepicker {
    min-width: 100% !important;
  }

  .p-datepicker tbody td {
    text-align: center;
  }

  .p-button-icon-only {
    width: 2.5rem;
    padding: 0.429rem 0;
    border-radius: 0 10px 10px 0 !important;
  }

  .p-button:enabled:hover {
    background: var(--color-6);
    border-color: transparent;
  }

  .p-datepicker .p-datepicker-header {
    font-size: 0.8rem !important;
    background: white !important;
    border-color: white !important;
  }

  .p-datepicker .p-datepicker-header,
  .p-datepicker-title button {
    color: var(--color-39) !important;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev,
  .p-datepicker .p-datepicker-header .p-datepicker-next {
    color: var(--color-38) !important;
  }

  .p-datepicker thead tr {
    color: var(--colo-39) !important;
    background: white !important;
  }

  .p-datepicker-title button {
    font-size: 0.8rem;
    font-weight: 600 !important;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev .p-icon,
  .p-datepicker .p-datepicker-header .p-datepicker-next .p-icon {
    width: 0.8rem;
    height: 0.8rem;
  }

  .calendar-app-moded-input {
    border: none !important;
    width: calc(100% - 2.5rem) !important;
    height: 2rem !important;
    padding: 5px 15px;
    background: var(--color-37) !important;
    border-radius: 10px 0 0 10px !important;
  }

  .calendar-app-moded-input,
  .calendar-app-moded-input:enabled:focus,
  .p-button:focus,
  .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
  .p-datepicker .p-datepicker-header .p-datepicker-next:focus,
  .p-link:focus,
  .p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):focus,
  .p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):focus,
  .p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):focus,
  .p-datepicker table td > span:focus {
    box-shadow: none !important;
  }

  .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight,
  .p-datepicker tbody td a.p-active,
  .p-datepicker tbody td span.p-highlight {
    background-color: var(--color-6) !important;
    color: var(--color-9) !important;
  }
}

.calendar-height {
  height: 33px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;

  .p-inputtext {
    top: 50% !important;
  }
}
