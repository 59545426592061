.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-spacing: 0;
  border-collapse: collapse;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.p-datatable table {
  table-layout: auto !important;
}

.tbl-app .p-datatable-thead>tr>th {
  border: 0px !important;
  background-color: #fff !important;
  color: var(--color-14);
  text-align: left;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  font-size: 12px !important;
}

.tbl-app .p-datatable-tbody>tr>td {
  border: 0px !important;
  padding: 10px !important;
  padding-left: 12px !important;
  background-color: #f2f2f2 !important;
  font-size: 12px !important;
}

.tbl-app tr:hover td {
  background-color: #eaeaea !important;
}

.tbl-app table {
  border-collapse: separate !important;
  border-spacing: 0 5px !important;
}

.tbl-app tr td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px
}

.tbl-app tr td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px
}

.p-table .p-datatable-tbody>tr {
  font-family: var(--font-family-1) !important;
  color: #212121 !important;
  font-size: 16px !important;
}

.p-table .p-datatable-tbody .p-highlight {
  background-color: #BF3A42 !important;
}

.tblwthclr .p-datatable-tbody>tr:nth-child(even) {
  background-color: #fff !important;
}

.tblwthclr .p-datatable-tbody>tr.p-selectable-row:not(.p-highlight):not(.p-contextmenu-selected):hover {
  cursor: default !important;
  background-color: #eaeaea !important;
}

.tbl .p-datatable-thead>tr>th {
  font-family: var(--font-family-1) !important;
  border: 0px !important;
  background-color: #fff !important;
  color: #7e7e7e !important;
  text-align: left;
  padding-bottom: 0px !important;
  font-size: 14px !important;
}

.tbl .p-datatable-tbody>tr>td {
  border: 0px !important;
  padding: 10px !important;
  padding-left: 12px !important;
  font-size: 15px !important;
}

.tbl tr:hover td {
  background-color: rgb(192, 56, 85) !important;
  color: #fff;
}

.tbl table {
  border-collapse: separate !important;
  border-spacing: 0 5px !important;
}

.tbl tr td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px
}

.tbl tr td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px
}

.tbl-emphasis .p-datatable-thead>tr>th {
  background-color: var(--color-6) !important;
  border: none;
  color: white;
}

.p-datatable .p-datatable-tbody>tr>td {
  font-weight: 500;
}

.tbl-app-v2 .p-datatable-thead>tr>th:last-child {
  border-radius: 0;
}

.tbl-app-v2 .p-datatable-thead>tr>th:first-child,
.tbl-app-v2 .p-datatable-tbody>tr>td:first-child {
  padding-left: 1rem !important;
}

.tbl-app-v2 .p-datatable-thead>tr>th, 
.tbl-app-v3 .p-datatable-tfoot>tr>td {
  border: 0px !important;
  background-color: var(--color-35) !important;
  color: var(--color-38);
  text-align: left;
  padding: 0.5rem !important;
  font-size: .91rem !important;
}

.tbl-app-v2 .p-datatable-tbody>tr>td {
  font-size: 12px !important;
  text-align: left !important;
  border: 1px solid var(--color-36) !important;
  border-width: 0 0 1px 0 !important;
  padding: 0.5rem !important;
}

.ptable-container-rounded-borders {
  background: var(--color-36);
  border-radius: 10px;
  padding: 1px;
}

.tbl-app-v3 table {
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
}

.tbl-app-v3 .p-datatable-wrapper .p-datatable-table .p-datatable-thead>tr>th {
  font-size: 14px !important;
}

.tbl-app-v4 .p-datatable-wrapper .p-datatable-table .p-datatable-thead>tr>th {
  border: 0px !important;
  font-size: 14px !important;
  font-weight: bold;
  font-family: var(--font-family-1);
  color: #495057;
  background: #F4F4F4 !important;
}

.tbl-app-v4 .p-datatable-wrapper .p-datatable-table .p-datatable-tbody>tr>td {
  border: 0px !important;
  border-bottom: 1px solid #D1D1D1 !important;
  font-size: 13px !important;
  font-family: var(--font-family-1);
  color: #495057;
}

.tbl-app-v4 .p-datatable-wrapper .p-datatable-table .p-datatable-thead>tr .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #495057 !important;
  border: none !important;
}

.tbl-app-v4 .p-datatable-wrapper .p-datatable-table .p-datatable-thead>tr>th.p-element.p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #F4F4F4 !important;
  background: white;
  border-radius: 10px;
}

.tbl-app-v3 .p-datatable-thead>tr>th:last-child {
  border-radius: 0 10px 0 0;
}

.tbl-app-v3 .p-datatable-thead>tr>th:first-child {
  border-radius: 10px 0 0 0;
}

.tbl-app-v3 .p-datatable-thead>tr>th:first-child,
.tbl-app-v3 .p-datatable-tbody>tr>td:first-child {
  padding-left: 1.5rem !important;
}

.tbl-app-v3 .p-datatable-tbody {
  border: none !important;
  border-width: 0 !important;
}

.tbl-app-v3 .p-datatable-tbody>tr>td:first-child,
.tbl-app-v3 .p-datatable-tfoot>tr>td:first-child {
  border-radius: 0 0 0 10px !important;
}

.tbl-app-v3 .p-datatable-tbody>tr>td:last-child,
.tbl-app-v3 .p-datatable-tfoot>tr>td:last-child {
  border-radius: 0 0 10px 0 !important;
}

.tbl-app-v3 .p-datatable-tbody>tr>td {
  border: none !important;
}

.tbl-app-v3 .p-datatable-tbody>tr {
  border: 1px solid var(--color-36) !important;
  border-radius: 0 0 10px 10px !important;
  border-width: 1px 0 !important;
}

.tbl-app-v3 .p-datatable-tbody>tr:last-child,
.tbl-app-v4 .p-datatable-tbody>tr {
  border: none;
  border-width: 0 !important;
}

.custom-scroll-panel-table {
  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 0 0 10px 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #495057;
    border-radius: 10px;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #cea4a4;
  }
}

.tbl-app-v5 table {
  border-collapse: separate !important;
  border-spacing: 0 0px !important;
}

.tbl-app-v5 tr td:last-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.tbl-app-v5 tr td:first-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.tbl-app-v5 tr th:last-child {
  border-top-right-radius: 15px !important;
}

.tbl-app-v5 tr th:first-child {
  border-top-left-radius: 15px !important;
}

.tbl-app-v5 .p-datatable-wrapper .p-datatable-table .p-datatable-thead {
  font-size: 12px !important;
  color: #495057;
  background: #edf0f5 !important;
}
.tbl-app-v5 .p-datatable-wrapper .p-datatable-table .p-datatable-thead>tr>th {
  border: 0px !important;
  font-size: 12px !important;
  color: #495057;
  background: #edf0f5 !important;
}

.tbl-app-v5 .p-datatable-wrapper .p-datatable-table .p-datatable-tbody>tr>td {
  border: 0px !important;
  border-bottom: 1px solid #d0d0d0 !important;
  font-size: 12px !important;
  color: #495057;
  background-color: white !important;
}
.tbl-app-v5 .p-datatable-wrapper .p-datatable-table .p-datatable-tbody>tr>td:last-child {
  border-right: 1px solid #d0d0d0 !important;
}
.tbl-app-v5 .p-datatable-wrapper .p-datatable-table .p-datatable-tbody>tr>td:first-child {
  border-left: 1px solid #d0d0d0 !important;
}
.tbl-app-v5 .p-datatable-wrapper .p-datatable-table .p-datatable-tbody>tr:hover td {
  background-color: #f2f2f2 !important;
}

.tbl-app-v5 .p-datatable-wrapper .p-datatable-table .p-datatable-thead>tr .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #495057 !important;
  border: none !important;
}

.tbl-app-v5 .p-datatable-wrapper .p-datatable-table .p-datatable-thead>tr>th.p-element.p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #edf0f5 !important;
  background: white;
  border-radius: 10px;
}

.tbl-app-v5 .p-datatable-tbody>tr {
  border: none;
  border-width: 0 !important;
}



.article-table {
  table-layout: fixed;
  width: 100%;
}

.article-table td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tbl-app-v6 .p-datatable-thead>tr>th {
  text-align: left;
  padding: 0.75rem;
  border: 1px solid #D0D0D0;
  border-width: 0 0 1px 0;
  font-weight: 700;
  background: var(--color-35);
  transition: box-shadow 0.2s;
  font-size: 14px !important;
  font-family: var(--font-family-1);
  color: var(--color-38);
  line-height: 17.07px;
}

.tbl-app-v6 .p-datatable-thead>tr>th:first-child {
  border-top-left-radius: 8px;
}

.tbl-app-v6 .p-datatable-thead>tr>th:last-child {
  border-top-right-radius: 8px;
}

.tbl-app-v6 .p-datatable-tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 8px;
}

.tbl-app-v6 .p-datatable-tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 8px;
}

.tbl-app-v6 .p-datatable-tbody>tr>td:first-child {
  border-left: 1px solid #D0D0D0;
}

.tbl-app-v6 .p-datatable-tbody>tr>td:last-child {
  border-right: 1px solid #D0D0D0;
}

.tbl-app-v6 .p-datatable-tbody>tr>td {
  text-align: left;
  border: 1px solid #D0D0D0;
  border-width: 0 0 1px 0;
  padding: 0.75rem;
  font-weight: 400;
  font-size: 14px;
  font-family: var(--font-family-1);
  color: var(--color-38);
}

.tbl-app-v6 .p-datatable-tbody>tr:hover {
  background-color: #f2f2f2;
  color: #343a40;
  cursor: pointer;
}

.tbl-app-v6-paginador {
  background: #ffffff;
  color: #333333;
  border: solid #ffffff;
  border-width: 1px;
  padding: 0;
  border-radius: 3px;
}