.dropdown-app {
  border-radius: 8px !important;
  border: 0px !important;
  background: var(--color-9) !important;
  height: 32px !important;
  font-size: 14px !important;
  align-items: center !important;
}

.dropdown-app-wr {
  border-radius: 8px 0px 0px 8px !important;
}

.dropdown-app .p-dropdown-trigger {
  border-radius: 8px !important;
}

.dropdown-app input[type="text"]::placeholder,
.dropdown-app .p-placeholder {
  font-family: var(--font-family-1) !important;
  font-size: 14px !important;
  color: #66676A !important;
}

.dropdown-app .p-dropdown-label {
  border-radius: 8px !important;
  background-color: #F0F3F4 !important;
  font-family: var(--font-family-1) !important;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px !important;
}

.dropdown-app .p-dropdown-label-without-border-right {
  border-radius: 8px !important;
  background-color: #F0F3F4 !important;
  font-family: var(--font-family-1) !important;
  font-size: 14px !important;
  padding-left: 8px;
  padding-right: 8px;
}

.dropdown-app:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2em #fff !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: var(--color-7);
}

.dropdown-config-plantilla {
  border-radius: 8px !important;
  border: 0px !important;
}

.dropdown-config-plantilla.p-dropdown {
  display: inline-block !important;
  position: relative !important;
  cursor: pointer !important;
  vertical-align: middle !important;
  min-width: inherit !important;
}

.dropdown-config-plantilla .p-dropdown-trigger {
  background-color: var(--color-13) !important;
  border-radius: 8px !important;
}

.dropdown-config-plantilla input[type="text"]::placeholder,
.dropdown-config-plantilla .p-placeholder {
  font-family: var(--font-family-1) !important;
  font-size: 14px !important;
  color: var(--color-15) !important;
}

.dropdown-config-plantilla .p-dropdown-label {
  border-radius: 8px !important;
  background-color: var(--color-13) !important;
  font-family: var(--font-family-1) !important;
  font-size: 14px !important;
  padding-right: 3em !important;
}

.app-wico .p-dropdown-trigger-icon {
  display: none !important
}

.down-ad .p-dropdown-trigger-icon {
  display: none !important
}

.down-ad .p-dropdown-label {
  padding: 0px !important;
  font-family: var(--font-family-1) !important;
  font-size: 14px !important;
  line-height: 22.8px !important;
}

.down-ad input[type="text"]::placeholder,
.down-ad .p-placeholder {
  font-family: var(--font-family-1) !important;
  font-size: 14px !important;
}

.p-dropdown-items {
  font-family: var(--font-family-1) !important;
  font-size: 14px !important
}

.bg-clr .p-inputtext {
  background-color: #f8f9fa !important
}

.bg-clr .p-dropdown-trigger {
  background-color: #f8f9fa !important
}

.dropdown-config-plantilla .p-dropdown.p-disabled .p-dropdown-labe {
  color: var(--color-14) !important;
}

.p-dropdown-panel .p-dropdown-filter-container>.p-inputtext:enabled:focus:not(.p-state-error) {
  box-shadow: none !important;
  border-color: var(--color-6) !important;
}

.p-dropdown-panel .p-dropdown-filter-container>.p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon {
  outline: none !important;
}

li.p-dropdown-item.p-rounded.p-highlight {
  color: var(--color-13) !important;
  background-color: var(--color-18) !important;
}

.p-dropdown-clear-icon {
  position: absolute;
  right: 2em;
  top: 50%;
  height: 1em;
  margin-top: -0.5em !important;
}

.dropdown-app-custom-icon {
  border-radius: 10px 8px 8px 10px !important;
  border: 0px !important;
  background: var(--color-6) !important;
  height: 32px !important;
  font-size: 14px !important;
  align-items: center !important;
  position: relative;

  .p-dropdown-label {
    background: #F0F3F4;
    color: #66676A !important;
    font-family: var(--font-family-1) !important;
    font-size: 14px !important;
    border-radius: 8px 0 0 8px !important;
  }

  .p-dropdown-trigger {
    background: transparent;
    color: white;
  }
}

.dropdown-app-custom-icon:not(.p-disabled).p-focus {
  box-shadow: none !important;
}