.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--color-5);
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  min-width: 1.75rem;
  height: 1.75rem;
  line-height: 1rem;
  font-size: 0.875rem;
  font-family: Roboto;
  font-weight: 700;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  color: #535353;
}

.p-steps .p-steps-item.p-highlight .p-steps-title,
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  font-size: var(--font-size-5);
  font-family: Roboto;
}

p-steps [role="tablist"] li:not([aria-selected="true"] ~ li) .p-steps-number {
  background: var(--color-5) !important;
  color: white !important;
}

p-steps [role="tablist"] li:not([aria-selected="true"] ~ li) .p-steps-title {
  font-weight: 700;
  color: #535353 !important;
}

.steps-horizontal li.p-steps-item a.p-menuitem-link {
  flex-direction: row;
  gap: 0.5rem;
  background: #ffffff;
  padding-inline: 0.5rem;
  z-index: 1;
}

.steps-horizontal .p-steps-item:before {
  top: 100%;
}

.steps-horizontal li.p-steps-item a.p-menuitem-link > .p-steps-title {
  margin-top: 0;
}

.steps-horizontal .p-steps ul {
  justify-content: space-between;
}

.steps-horizontal .p-steps ul:before {
  content: " ";
  border-top: 1px solid #d8dae2;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
}

.steps-horizontal .p-steps-item {
  flex: initial;
}

.steps-horizontal .p-steps .p-steps-item:before {
  content: none;
}

.steps-horizontal .p-steps-item:not(.p-steps-current) .p-steps-number {
  color: white;
  background: #666666;
}