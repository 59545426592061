.check-app .p-checkbox-box.p-active {
    border-color: var(--color-16) !important;
    background-color: var(--color-16) !important;
}

.check-app .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 0.2em rgb(225, 134, 134) !important;
    border-color: var(--color-16) !important;
}

.check-app .p-checkbox-box.p-active:not(.p-disabled):hover {
    border-color: var(--color-16) !important;
    background-color: var(--color-16) !important;
}

/* textos para check-box */
.p-checkbox-label {
    font-weight: 700 !important;
}

.p-checkbox .p-checkbox-box.p-active {
    border-color: var(--color-6) !important;
    background-color: var(--color-6) !important;
    color: #ffffff;
}

.p-radiobutton {
    width: 100%
}

.p-radiobutton .p-radiobutton-box{
    --size: 1.35rem;
    width: var(--size);
    height: var(--size);
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    --fill: 60%;
    height: var(--fill);
    width: var(--fill);
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: var(--color-6) !important;
}

.app-radiobutton .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: var(--color-6);
    background: var(--color-6);
}
  
.app-radiobutton .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    --fill: 70% !important;
}