/*************   PAPELERIA PC  **************/
.contenedor-previsualizacion-impresion.medidas-pc{
    background-color: var(--color-13);
    min-width: 430px;
    max-width: 430px;
    height: 527px;
    border: 1px dashed var(--color-7);
    position: relative;
}

.contenedor-previsualizacion-impresion.medidas-pc>
.contenedor-previsualizacion-contenido-encabezado{
    position: absolute;
    min-width: 430px;
    max-width: 430px;
    height: 69px;
    background: #ebf0fa;
    top: 28px;
    padding: 0px 10px;
}

.contenedor-previsualizacion-impresion.medidas-pc>
.contenedor-previsualizacion-contenido-central{
    position:absolute;
    top: 97px;
    width: 430px;
    height: 331px;
    background: #ebf0fa;
    padding: 0 10px;
}
.medidas-pc .parrafo-contenido-central{
    margin: 0 !important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}
.medidas-pc .parrafo-contenido-central.inferior{
    line-height: inherit;
}

.medidas-pc .position-contenido-superior-1{
    position: absolute;
    top: 0;
}
.medidas-pc .position-contenido-superior-2{
    position: absolute;
    top: 0;
    left: 225px;
}
.medidas-pc .position-contenido-superior-3{
    position: absolute;
    top: 14px;
}
.medidas-pc .position-contenido-superior-4{
    position: absolute;
    top: 15px;
    left: 225px;
}
.medidas-pc .position-contenido-superior-5{
    position: absolute;
    top: 15px;
    left: 325px;
}
.medidas-pc .position-contenido-superior-6{
    position: absolute;
    top: 15px;
    left: 370px;
}

.medidas-pc .position-contenido-superior-7{
    position: absolute;
    top: 15px;
    left: 392px;
}
.medidas-pc .position-contenido-superior-9{
    position: absolute;
    top: 30px;
}
.medidas-pc .position-contenido-superior-10{
    position: absolute;
    top: 30px;
    left: 225px;
}
.medidas-pc .position-contenido-superior-11{
    position: absolute;
    top: 45px;
}
.medidas-pc .position-contenido-superior-12{
    position: absolute;
    top: 45px;
    left: 225px;
}
.medidas-pc .position-contenido-superior-13{
    position: absolute;
    top: 60px;
}

.medidas-pc .position-contenido-superior-14{
    position: absolute;
    top: 90px;
}
.medidas-pc .position-contenido-superior-titulo-producto{
    position: absolute;
    top: 75px;
    width: 100%;
    display: flex;
}
.medidas-pc .parrafo-contenido-central_titulo{
    margin: 0 auto!important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}
.medidas-pc .position-contenido-tabla-NRO{
    position: absolute;
    top: 105px;  
}
.medidas-pc .position-contenido-tabla-DIRECTO{
    position: absolute;
    top: 105px;  
    left:80px;
}
.medidas-pc .position-contenido-tabla-PLAN-DE-PREMIOS{
    position: absolute;
    top: 105px;  
    left:80px;
}

.medidas-pc .position-contenido-tabla-COMBINADO{
    position: absolute;
    top: 105px;
    left: 160px;  
}

.medidas-pc .position-contenido-tabla-2C{
    position: absolute;
    top: 105px;
    left: 340px;  
}

.medidas-pc .position-contenido-tabla-1C{
    position: absolute;
    top: 105px;
    left: 270px; 
}

.medidas-pc .position-contenido-tabla-APOSTADO{
    position: absolute;
    top: 230px;
}

.medidas-pc .position-contenido-tabla-IVA{
    position: absolute;
    top: 230px;
    left: 120px; 
}

.medidas-pc .position-contenido-tabla-PAGADO{
    position: absolute;
    top: 245px;
}

.medidas-pc .position-contenido-tabla-ENCIME{
    position: absolute;
    top: 245px;
    left: 120px; 
}

.medidas-pc .position-contenido-inferior-1{
    position: absolute;
    top: 260px;
    z-index: 2;
}
.medidas-pc .position-contenido-inferior-2{
    position: absolute;
    top: 275px;
    z-index: 2;
}
.medidas-pc .position-contenido-inferior-3{
    position: absolute;
    top: 290px;
    z-index: 2;
}
.medidas-pc .position-contenido-inferior-4{
    position: absolute;
    top: 305px;
    z-index: 2;
}


/*************   PAPELERIA Q2  **************/

.contenedor-previsualizacion-impresion.medidas-Q2{
    background-color: var(--color-13);
    min-width: 351px;
    max-width: 351px;
    height: 525px;
    border: 1px dashed var(--color-7);
    position: relative;
}

.contenedor-previsualizacion-impresion.medidas-Q2>
.contenedor-previsualizacion-contenido-central{
    position:absolute;
    top: 83px;
    width: 351px;
    height: 331px;
    background: #ebf0fa;
    padding: 0 10px;
}


.contenedor-previsualizacion-impresion.medidas-Q2>
.contenedor-previsualizacion-contenido-encabezado
{
    position: absolute;
    min-width: 351px;
    max-width: 351px;
    height: 69px;
    background: #ebf0fa;
    top: 28px;
    padding: 0px 10px;
}


.contenedor-previsualizacion-impresion.medidas-Q2>
.contenedor-previsualizacion-contenido-footer{
    position: absolute;
    min-width: 351px;
    max-width: 351px;
    height: 69px;
    background: #ebf0fa;
    bottom: 40px;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: var(--color-6);
    font-weight: 500;
}

.medidas-Q2 .parrafo-contenido-central{
    margin: 0 !important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}



.medidas-Q2 .parrafo-contenido-central.inferior{
    line-height: inherit;
}

.medidas-Q2 .position-contenido-superior-1{
    position: absolute;
    top: 0;
}
.medidas-Q2 .position-contenido-superior-2{
    position: absolute;
    top: 0;
    left: 210px;
}

.medidas-Q2 .position-contenido-superior-3{
    position: absolute;
    top: 14px;
}
.medidas-Q2 .position-contenido-superior-4{
    position: absolute;
    top: 15px;
    left: 141px;
}
.medidas-Q2 .position-contenido-superior-5{
    position: absolute;
    top: 15px;
    left: 224px;
}
.medidas-Q2 .position-contenido-superior-6{
    position: absolute;
    top: 15px;
    left: 263px;
}

.medidas-Q2 .position-contenido-superior-7{
    position: absolute;
    top: 15px;
    left: 280px;
}
.medidas-Q2 .position-contenido-superior-9{
    position: absolute;
    top: 30px;
}
.medidas-Q2 .position-contenido-superior-10{
    position: absolute;
    top: 30px;
    left: 157px;
}
.medidas-Q2 .position-contenido-superior-11{
    position: absolute;
    top: 45px;
}
.medidas-Q2 .position-contenido-superior-12{
    position: absolute;
    top: 45px;
    left: 157px;
}
.medidas-Q2 .position-contenido-superior-13{
    position: absolute;
    top: 60px;
}

.medidas-Q2 .position-contenido-superior-14{
    position: absolute;
    top: 90px;
}
.medidas-Q2 .position-contenido-superior-titulo-producto{
    position: absolute;
    top: 75px;
    width: 100%;
    display: flex;
}
.medidas-Q2 .parrafo-contenido-central_titulo{
    margin: 0 auto!important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}
.medidas-Q2 .position-contenido-tabla-NRO{
    position: absolute;
    top: 105px;  
}
.medidas-Q2 .position-contenido-tabla-DIRECTO{
    position: absolute;
    top: 105px;  
    left:65px;
}
.medidas-Q2 .position-contenido-tabla-PLAN-DE-PREMIOS{
    position: absolute;
    top: 105px;  
    left:80px;
}

.medidas-Q2 .position-contenido-tabla-COMBINADO{
    position: absolute;
    top: 105px;
    left: 130px;  
}

.medidas-Q2 .position-contenido-tabla-2C{
    position: absolute;
    top: 105px;
    left: 215px;  
}

.medidas-Q2 .position-contenido-tabla-1C{
    position: absolute;
    top: 105px;
    left: 270px; 
}

.medidas-Q2 .position-contenido-tabla-APOSTADO{
    position: absolute;
    top: 230px;
}

.medidas-Q2 .position-contenido-tabla-IVA{
    position: absolute;
    top: 230px;
    left: 120px; 
}

.medidas-Q2 .position-contenido-tabla-PAGADO{
    position: absolute;
    top: 245px;
}

.medidas-Q2 .position-contenido-tabla-ENCIME{
    position: absolute;
    top: 245px;
    left: 120px; 
}

.medidas-Q2 .position-contenido-inferior-1{
    position: absolute;
    top: 260px;
    z-index: 2;
}
.medidas-Q2 .position-contenido-inferior-2{
    position: absolute;
    top: 275px;
    z-index: 2;
}
.medidas-Q2 .position-contenido-inferior-3{
    position: absolute;
    top: 290px;
    z-index: 2;
}
.medidas-Q2 .position-contenido-inferior-4{
    position: absolute;
    top: 305px;
    z-index: 2;
}






/*************   PAPELERIA T2  **************/


.contenedor-previsualizacion-impresion.medidas-T2{
    background-color: var(--color-13);
    min-width: 430px;
    max-width: 430px;
    height: 527px;
    border: 1px dashed var(--color-7);
    position: relative;
}

.contenedor-previsualizacion-impresion.medidas-T2>
.contenedor-previsualizacion-contenido-central{
    position:absolute;
    top: 97px;
    width: 430px;
    height: 331px;
    background: #ebf0fa;
    padding: 0 10px;
}

.contenedor-previsualizacion-impresion.medidas-T2>
.contenedor-previsualizacion-contenido-encabezado
{
    position: absolute;
    min-width: 430px;
    max-width: 430px;
    height: 69px;
    background: #ebf0fa;
    top: 28px;
    padding: 0px 10px;
}

.medidas-T2 .parrafo-contenido-central{
    margin: 0 !important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}
.medidas-T2 .parrafo-contenido-central.inferior{
    line-height: inherit;
}
.medidas-T2 .position-contenido-superior-1{
    position: absolute;
    top: 0;
}
.medidas-T2 .position-contenido-superior-2{
    position: absolute;
    top: 0;
    left: 225px;
}
.medidas-T2 .position-contenido-superior-3{
    position: absolute;
    top: 14px;
}
.medidas-T2 .position-contenido-superior-4{
    position: absolute;
    top: 15px;
    left: 225px;
}
.medidas-T2 .position-contenido-superior-5{
    position: absolute;
    top: 15px;
    left: 325px;
}
.medidas-T2 .position-contenido-superior-6{
    position: absolute;
    top: 15px;
    left: 370px;
}

.medidas-T2 .position-contenido-superior-7{
    position: absolute;
    top: 15px;
    left: 392px;
}
.medidas-T2 .position-contenido-superior-9{
    position: absolute;
    top: 30px;
}
.medidas-T2 .position-contenido-superior-10{
    position: absolute;
    top: 30px;
    left: 225px;
}
.medidas-T2 .position-contenido-superior-11{
    position: absolute;
    top: 45px;
}
.medidas-T2 .position-contenido-superior-12{
    position: absolute;
    top: 45px;
    left: 225px;
}
.medidas-T2 .position-contenido-superior-13{
    position: absolute;
    top: 60px;
}

.medidas-T2 .position-contenido-superior-14{
    position: absolute;
    top: 90px;
}
.medidas-T2 .position-contenido-superior-titulo-producto{
    position: absolute;
    top: 75px;
    width: 100%;
    display: flex;
}
.medidas-T2 .parrafo-contenido-central_titulo{
    margin: 0 auto!important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}
.medidas-T2 .position-contenido-tabla-NRO{
    position: absolute;
    top: 105px;  
}
.medidas-T2 .position-contenido-tabla-DIRECTO{
    position: absolute;
    top: 105px;  
    left:80px;
}
.medidas-T2 .position-contenido-tabla-PLAN-DE-PREMIOS{
    position: absolute;
    top: 105px;  
    left:80px;
}

.medidas-T2 .position-contenido-tabla-COMBINADO{
    position: absolute;
    top: 105px;
    left: 160px;  
}

.medidas-T2 .position-contenido-tabla-2C{
    position: absolute;
    top: 105px;
    left: 340px;  
}

.medidas-T2 .position-contenido-tabla-1C{
    position: absolute;
    top: 105px;
    left: 270px; 
}

.medidas-T2 .position-contenido-tabla-APOSTADO{
    position: absolute;
    top: 230px;
}

.medidas-T2 .position-contenido-tabla-IVA{
    position: absolute;
    top: 230px;
    left: 120px; 
}

.medidas-T2 .position-contenido-tabla-PAGADO{
    position: absolute;
    top: 245px;
}

.medidas-T2 .position-contenido-tabla-ENCIME{
    position: absolute;
    top: 245px;
    left: 120px; 
}

.medidas-T2 .position-contenido-inferior-1{
    position: absolute;
    top: 260px;
    z-index: 2;
}
.medidas-T2 .position-contenido-inferior-2{
    position: absolute;
    top: 275px;
    z-index: 2;
}
.medidas-T2 .position-contenido-inferior-3{
    position: absolute;
    top: 290px;
    z-index: 2;
}
.medidas-T2 .position-contenido-inferior-4{
    position: absolute;
    top: 305px;
    z-index: 2;
}








/************************************** MEDIDAS SUPER ASTRO PAPELERIA PC ************************************************/



.contenedor-previsualizacion-impresion.medidas-pc-SP{
    background-color: var(--color-13);
    min-width: 430px;
    max-width: 430px;
    height: 527px;
    border: 1px dashed var(--color-7);
    position: relative;
}

.contenedor-previsualizacion-impresion.medidas-pc-SP>
.contenedor-previsualizacion-contenido-encabezado{
    position: absolute;
    min-width: 430px;
    max-width: 430px;
    height: 69px;
    background: #ebf0fa;
    top: 28px;
    padding: 0px 10px;
}

.contenedor-previsualizacion-impresion.medidas-pc-SP>
.contenedor-previsualizacion-contenido-central{
    position:absolute;
    top: 97px;
    width: 430px;
    height: 331px;
    background: #ebf0fa;
    padding: 0 10px;
}
.medidas-pc-SP .parrafo-contenido-central{
    margin: 0 !important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}
.medidas-pc-SP .parrafo-contenido-central.inferior{
    line-height: inherit;
}

.medidas-pc-SP .position-contenido-superior-0{
    position: absolute;
    top: 0;
    line-height: 15px;
}

.medidas-pc-SP .position-contenido-superior-1{
    position: absolute;
    top: 30px;
}
.medidas-pc-SP .position-contenido-superior-2{
    position: absolute;
    top: 30px;
    left: 225px;
}
.medidas-pc-SP .position-contenido-superior-3{
    position: absolute;
    top: 45px;
}
.medidas-pc-SP .position-contenido-superior-4{
    position: absolute;
    top: 45px;
    left: 225px;
}
.medidas-pc-SP .position-contenido-superior-5{
    position: absolute;
    top: 45px;
    left: 325px;
}
.medidas-pc-SP .position-contenido-superior-6{
    position: absolute;
    top: 45px;
    left: 370px;
}

.medidas-pc-SP .position-contenido-superior-7{
    position: absolute;
    top: 45px;
    left: 392px;
}
.medidas-pc-SP .position-contenido-superior-9{
    position: absolute;
    top: 60px;
}
.medidas-pc-SP .position-contenido-superior-10{
    position: absolute;
    top: 60px;
    left: 225px;
}
.medidas-pc-SP .position-contenido-superior-11{
    position: absolute;
    top: 75px;
}
.medidas-pc-SP .position-contenido-superior-12{
    position: absolute;
    top: 75px;
    left: 225px;
}
.medidas-pc-SP .position-contenido-superior-13{
    position: absolute;
    top: 90px;
}

.medidas-pc-SP .position-contenido-superior-14{
    position: absolute;
    top: 110px;
}
.medidas-pc-SP .position-contenido-superior-titulo-producto{
    position: absolute;
    top: 95px;
    width: 100%;
    display: flex;
}
.medidas-pc-SP .parrafo-contenido-central_titulo{
    margin: 0 auto!important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}
.medidas-pc-SP .position-contenido-tabla-NRO{
    position: absolute;
    top: 130px;
    line-height: 15px; 
}

.medidas-pc-SP .position-contenido-tabla-PLAN-DE-PREMIOS{
    position: absolute;
    top: 130px;  
    left: 250px;  
    line-height: 15px;
}


.medidas-pc-SP .position-contenido-tabla-VALOR{
    position: absolute;
    top: 130px;  
    left:80px;
    line-height: 15px;
}

.medidas-pc-SP .position-contenido-tabla-ASTRO{
    position: absolute;
    top: 130px;  
    left: 155px; 
    line-height: 15px;
}

.medidas-pc-SP  .position-contenido-tabla-APOSTADO{
    position: absolute;
    top: 230px;
}

.medidas-pc-SP  .position-contenido-tabla-IVA{
    position: absolute;
    top: 230px;
    left: 120px; 
}

.medidas-pc-SP  .position-contenido-tabla-PAGADO{
    position: absolute;
    top: 245px;
}

.medidas-pc-SP  .position-contenido-tabla-ENCIME{
    position: absolute;
    top: 245px;
    left: 120px; 
}

.medidas-pc-SP .position-contenido-inferior-1{
    position: absolute;
    top: 260px;
    z-index: 2;
}
.medidas-pc-SP .position-contenido-inferior-2{
    position: absolute;
    top: 275px;
    z-index: 2;
}
.medidas-pc-SP .position-contenido-inferior-3{
    position: absolute;
    top: 290px;
    z-index: 2;
}
.medidas-pc-SP .position-contenido-inferior-4{
    position: absolute;
    top: 305px;
    z-index: 2;
}

/******************** MEDIDAS SUPER ASTRO TERMINAL Q2 ******************************/

.contenedor-previsualizacion-impresion.medidas-Q2-SP{
    background-color: var(--color-13);
    min-width: 351px;
    max-width: 351px;
    height: 525px;
    border: 1px dashed var(--color-7);
    position: relative;
}

.contenedor-previsualizacion-impresion.medidas-Q2-SP>
.contenedor-previsualizacion-contenido-central{
    position:absolute;
    top: 83px;
    width: 351px;
    height: 331px;
    background: #ebf0fa;
    padding: 0 10px;
}


.contenedor-previsualizacion-impresion.medidas-Q2-SP>
.contenedor-previsualizacion-contenido-encabezado
{
    position: absolute;
    min-width: 351px;
    max-width: 351px;
    height: 69px;
    background: #ebf0fa;
    top: 28px;
    padding: 0px 10px;
}


.contenedor-previsualizacion-impresion.medidas-Q2-SP>
.contenedor-previsualizacion-contenido-footer{
    position: absolute;
    min-width: 351px;
    max-width: 351px;
    height: 69px;
    background: #ebf0fa;
    bottom: 40px;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: var(--color-6);
    font-weight: 500;
}

.medidas-Q2-SP .parrafo-contenido-central{
    margin: 0 !important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}



.medidas-Q2-SP .parrafo-contenido-central.inferior{
    line-height: inherit;
}
.medidas-Q2-SP .position-contenido-superior-0{
    position: absolute;
    top: 0;
    line-height: 15px;
}

.medidas-Q2-SP .position-contenido-superior-1{
    position: absolute;
    top: 30px;
}
.medidas-Q2-SP .position-contenido-superior-2{
    position: absolute;
    top: 30px;
    left: 210px;
}

.medidas-Q2-SP .position-contenido-superior-3{
    position: absolute;
    top: 45px;
}
.medidas-Q2-SP .position-contenido-superior-4{
    position: absolute;
    top: 45px;
    left: 141px;
}
.medidas-Q2-SP .position-contenido-superior-5{
    position: absolute;
    top: 45px;
    left: 224px;
}
.medidas-Q2-SP .position-contenido-superior-6{
    position: absolute;
    top: 45px;
    left: 263px;
}

.medidas-Q2-SP .position-contenido-superior-7{
    position: absolute;
    top: 45px;
    left: 280px;
}
.medidas-Q2-SP .position-contenido-superior-9{
    position: absolute;
    top: 60px;
}
.medidas-Q2-SP .position-contenido-superior-10{
    position: absolute;
    top: 60px;
    left: 157px;
}
.medidas-Q2-SP .position-contenido-superior-11{
    position: absolute;
    top: 75px;
}
.medidas-Q2-SP .position-contenido-superior-12{
    position: absolute;
    top: 75px;
    left: 157px;
}
.medidas-Q2-SP .position-contenido-superior-13{
    position: absolute;
    top: 90px;
}

.medidas-Q2-SP .position-contenido-superior-14{
    position: absolute;
    top: 110px;
}
.medidas-Q2-SP .position-contenido-superior-titulo-producto{
    position: absolute;
    top: 95px;
    width: 100%;
    display: flex;
}
.medidas-Q2-SP .parrafo-contenido-central_titulo{
    margin: 0 auto!important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}
.medidas-Q2-SP .position-contenido-tabla-NRO{
    position: absolute;
    top: 130px;  
    line-height: 13px;
}

.medidas-Q2-SP .position-contenido-tabla-PLAN-DE-PREMIOS{
    position: absolute;
    top: 130px;  
    left: 225px;  
    line-height: 13px;
}


.medidas-Q2-SP .position-contenido-tabla-VALOR{
    position: absolute;
    top: 130px;  
    left:75px;
    line-height: 13px;
}

.medidas-Q2-SP .position-contenido-tabla-ASTRO{
    position: absolute;
    top: 130px;  
    left: 150px; 
    line-height: 13px;
}

.medidas-Q2-SP .position-contenido-tabla-APOSTADO{
    position: absolute;
    top: 230px;
}

.medidas-Q2-SP .position-contenido-tabla-IVA{
    position: absolute;
    top: 230px;
    left: 120px; 
}

.medidas-Q2-SP .position-contenido-tabla-PAGADO{
    position: absolute;
    top: 245px;
}

.medidas-Q2-SP .position-contenido-tabla-ENCIME{
    position: absolute;
    top: 245px;
    left: 120px; 
}

.medidas-Q2-SP .position-contenido-inferior-1{
    position: absolute;
    top: 260px;
    z-index: 2;
}
.medidas-Q2-SP .position-contenido-inferior-2{
    position: absolute;
    top: 275px;
    z-index: 2;
}
.medidas-Q2-SP .position-contenido-inferior-3{
    position: absolute;
    top: 290px;
    z-index: 2;
}
.medidas-Q2-SP .position-contenido-inferior-4{
    position: absolute;
    top: 305px;
    z-index: 2;
} 

/*********************************************/




.contenedor-previsualizacion-impresion.medidas-T2-SP{
    background-color: var(--color-13);
    min-width: 430px;
    max-width: 430px;
    height: 527px;
    border: 1px dashed var(--color-7);
    position: relative;
}

.contenedor-previsualizacion-impresion.medidas-T2-SP>
.contenedor-previsualizacion-contenido-encabezado{
    position: absolute;
    min-width: 430px;
    max-width: 430px;
    height: 69px;
    background: #ebf0fa;
    top: 28px;
    padding: 0px 10px;
}

.contenedor-previsualizacion-impresion.medidas-T2-SP>
.contenedor-previsualizacion-contenido-central{
    position:absolute;
    top: 97px;
    width: 430px;
    height: 331px;
    background: #ebf0fa;
    padding: 0 10px;
}
.medidas-T2-SP .parrafo-contenido-central{
    margin: 0 !important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}
.medidas-T2-SP .parrafo-contenido-central.inferior{
    line-height: inherit;
}

.medidas-T2-SP .position-contenido-superior-0{
    position: absolute;
    top: 0;
    line-height: 15px;
}

.medidas-T2-SP .position-contenido-superior-1{
    position: absolute;
    top: 30px;
}
.medidas-T2-SP .position-contenido-superior-2{
    position: absolute;
    top: 30px;
    left: 225px;
}
.medidas-T2-SP .position-contenido-superior-3{
    position: absolute;
    top: 45px;
}
.medidas-T2-SP .position-contenido-superior-4{
    position: absolute;
    top: 45px;
    left: 225px;
}
.medidas-T2-SP .position-contenido-superior-5{
    position: absolute;
    top: 45px;
    left: 325px;
}
.medidas-T2-SP .position-contenido-superior-6{
    position: absolute;
    top: 45px;
    left: 370px;
}

.medidas-T2-SP .position-contenido-superior-7{
    position: absolute;
    top: 45px;
    left: 392px;
}
.medidas-T2-SP .position-contenido-superior-9{
    position: absolute;
    top: 60px;
}
.medidas-T2-SP .position-contenido-superior-10{
    position: absolute;
    top: 60px;
    left: 225px;
}
.medidas-T2-SP .position-contenido-superior-11{
    position: absolute;
    top: 75px;
}
.medidas-T2-SP .position-contenido-superior-12{
    position: absolute;
    top: 75px;
    left: 225px;
}
.medidas-T2-SP .position-contenido-superior-13{
    position: absolute;
    top: 90px;
}

.medidas-T2-SP .position-contenido-superior-14{
    position: absolute;
    top: 110px;
}
.medidas-T2-SP .position-contenido-superior-titulo-producto{
    position: absolute;
    top: 95px;
    width: 100%;
    display: flex;
}
.medidas-T2-SP .parrafo-contenido-central_titulo{
    margin: 0 auto!important;
    padding: 0 !important;
    color: var(--color-6) !important;
    font-weight: 500;
}
.medidas-T2-SP .position-contenido-tabla-NRO{
    position: absolute;
    top: 130px;
    line-height: 15px; 
}

.medidas-T2-SP .position-contenido-tabla-PLAN-DE-PREMIOS{
    position: absolute;
    top: 130px;  
    left: 250px;  
    line-height: 15px;
}


.medidas-T2-SP .position-contenido-tabla-VALOR{
    position: absolute;
    top: 130px;  
    left:80px;
    line-height: 15px;
}

.medidas-T2-SP .position-contenido-tabla-ASTRO{
    position: absolute;
    top: 130px;  
    left: 155px; 
    line-height: 15px;
}

.medidas-T2-SP  .position-contenido-tabla-APOSTADO{
    position: absolute;
    top: 230px;
}

.medidas-T2-SP  .position-contenido-tabla-IVA{
    position: absolute;
    top: 230px;
    left: 120px; 
}

.medidas-T2-SP  .position-contenido-tabla-PAGADO{
    position: absolute;
    top: 245px;
}

.medidas-T2-SP  .position-contenido-tabla-ENCIME{
    position: absolute;
    top: 245px;
    left: 120px; 
}

.medidas-T2-SP .position-contenido-inferior-1{
    position: absolute;
    top: 260px;
    z-index: 2;
}
.medidas-T2-SP .position-contenido-inferior-2{
    position: absolute;
    top: 275px;
    z-index: 2;
}
.medidas-T2-SP .position-contenido-inferior-3{
    position: absolute;
    top: 290px;
    z-index: 2;
}
.medidas-T2-SP .position-contenido-inferior-4{
    position: absolute;
    top: 305px;
    z-index: 2;
}


/*********** CONTENEDORES EN COMUN **********/


.contenedor-previsualizacion-impresion.medidas-pc>
.contenedor-previsualizacion-contenido-encabezado>
.contenedor-previsualizacion-contenido-encabezado__border,
.contenedor-previsualizacion-impresion.medidas-T2>
.contenedor-previsualizacion-contenido-encabezado>
.contenedor-previsualizacion-contenido-encabezado__border,
.contenedor-previsualizacion-impresion.medidas-Q2>
.contenedor-previsualizacion-contenido-encabezado>
.contenedor-previsualizacion-contenido-encabezado__border,
.contenedor-previsualizacion-impresion.medidas-pc-SP>
.contenedor-previsualizacion-contenido-encabezado>
.contenedor-previsualizacion-contenido-encabezado__border,
.contenedor-previsualizacion-impresion.medidas-T2-SP>
.contenedor-previsualizacion-contenido-encabezado>
.contenedor-previsualizacion-contenido-encabezado__border,
.contenedor-previsualizacion-impresion.medidas-Q2-SP>
.contenedor-previsualizacion-contenido-encabezado>
.contenedor-previsualizacion-contenido-encabezado__border
{
    border: 1px solid var(--color-7);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 2px 10px;
}
.contenedor-previsualizacion-impresion.medidas-pc>
.contenedor-previsualizacion-contenido-footer,
.contenedor-previsualizacion-impresion.medidas-T2>
.contenedor-previsualizacion-contenido-footer,
.contenedor-previsualizacion-impresion.medidas-pc-SP>
.contenedor-previsualizacion-contenido-footer,
.contenedor-previsualizacion-impresion.medidas-T2-SP>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto
{
    position: absolute;
    min-width: 430px;
    max-width: 430px;
    height: 69px;
    background: #ebf0fa;
    bottom: 28px;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: var(--color-6);
    font-weight: 500;
}

.contenedor-previsualizacion-impresion.medidas-pc>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto,
.contenedor-previsualizacion-impresion.medidas-T2>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto,
.contenedor-previsualizacion-impresion.medidas-Q2>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto,
.contenedor-previsualizacion-impresion.medidas-pc-SP>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto,
.contenedor-previsualizacion-impresion.medidas-Q2-SP>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto,
.contenedor-previsualizacion-impresion.medidas-T2-SP>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto
{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.contenedor-previsualizacion-impresion.medidas-pc>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto>p,
.contenedor-previsualizacion-impresion.medidas-T2>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto>p,
.contenedor-previsualizacion-impresion.medidas-Q2>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto>p,
.contenedor-previsualizacion-impresion.medidas-pc-SP>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto>p,
.contenedor-previsualizacion-impresion.medidas-Q2-SP>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto>p,
.contenedor-previsualizacion-impresion.medidas-T2-SP>
.contenedor-previsualizacion-contenido-footer>
.contenedor-previsualizacion-contenido-footer-texto>p
{
    margin: 0;
    padding: 0;
}
