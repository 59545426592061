input {
    height: 33px;
}

.ad-input-label {
    margin-bottom: .2rem;
}

.obligatoriedad {
    color: var(--color-6);
}

.in-required {
    border-color: #a94442 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(169, 68, 66, .6) !important;
}

.ad-input-required {
    background-image: linear-gradient(to bottom, #a94442, #a94442), linear-gradient(to bottom, #a94442, #a94442) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(169, 68, 66, .6);
}

.ad-input {
    background: transparent no-repeat;
    background-image: linear-gradient(to bottom, #3f51b5, #3f51b5), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    border-width: 0;
    padding-bottom: 0px;
    padding-top: .25em;
    font-size: 1em;
    outline: medium none;
}

.ad-input:focus {
    background-size: 0 0px, 0% 0px;
}

.ad-input-style~.focus-border {
    position: relative;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #607D8B;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
}

.ad-input-style:focus~.focus-border {
    width: 100%;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    left: 0;
}

.ad-select {
    border: 0px !important;
    border-bottom: 1px solid #a6a6a6 !important;
    border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    width: 100% !important;
    vertical-align: bottom !important;
    box-shadow: 0 0 0px !important;
}

.ad-select-required {
    border: 0px !important;
    border-bottom: 1px solid #a94442 !important;
    border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(169, 68, 66, .6) !important;
    width: 100% !important;
    vertical-align: bottom !important;
}

.ad-calendar-s {
    border: 0px !important;
    border-bottom: 1px solid #a6a6a6 !important;
    border-radius: 8px !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    width: 100% !important;
    padding: 0px !important;
    background-color: #F0F3F4 !important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    outline: medium none;
    font-family: var(--font-family-1) !important;
}

.ad-calendar {
    border: 0px !important;
    border-bottom: 1px solid #a6a6a6 !important;
    border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    width: 100% !important;
    padding: 1px !important;
}

.ad-calendar-required {
    border: 0px !important;
    border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    width: 100% !important;
    padding: 1px !important;
    border-bottom: 1px solid #a94442 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(169, 68, 66, .6) !important;
}

.input-app-sm-button {
    border-radius: 5px;
    background-color: #F0F3F4 !important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    font-size: 14px !important;
    outline: medium none;
    font-family: var(--font-family-1) !important;
}

.input-app-sm {
    border-radius: 8px !important;
    background-color: #F0F3F4 !important;
    height: 32px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border: 0px !important;
    outline: medium none !important;
    font-size: 14px !important;
    font-family: var(--font-family-1) !important;
}

.input-app-sm-without-border-right {
    border-radius: 8px 0px 0px 8px;
    background-color: #F0F3F4 !important;
    height: 32px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    outline: medium none;
    font-size: 14px !important;
    font-family: var(--font-family-1) !important;
}


.input-app-sm-trans {
    border-radius: 8px !important;
    background-color: #F0F3F4 !important;
    height: 32px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border: 0px;
    outline: medium none;
    font-size: 14px !important;
    font-family: var(--font-family-1) !important;
}

.inputs-app {
    border-radius: 8px !important;
    height: 32px !important;
    background-color: #F0F3F4 !important;
    border: 0px !important;
    outline: medium none !important;
    font-family: var(--font-family-1) !important;
    font-size: 14px !important;
    padding: 6px 2px 6px 6px !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: var(--color-6) !important;
}

.input-app-mnst {
    border-radius: 8px;
    background-color: #F0F3F4 !important;
    padding: 0.6rem;
    font-family: var(--font-family-1);
    font-size: 14px !important;
    border: 0px;
    outline: medium none;
}

.input-est {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 14px !important;
    border: 1px solid #3A3F51;
    border-radius: 5px;
    color: #8A8080;
}

.select-est {
    font-size: 14px !important;
    border: 1px solid #3A3F51 !important;
    border-radius: 5px !important;
    color: #8A8080 !important;
}

.p-inputnumber-input-carga-resultados {
    flex: 1 1 auto !important;
    width: 60px !important;
    height: 25px !important;
    font-family: var(--font-family-1) !important;
    text-align: center !important;
    font-size: 14px !important;
    letter-spacing: 1px !important;
}




.p-chips .p-chips-multiple-container {
    border-radius: 8px !important;
    background-color: #F0F3F4 !important;
    height: 32px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border: 0px !important;
    outline: medium none !important;
    font-size: 14px !important;
    font-family: var(--font-family-1) !important;
    width: 100%;
}

.p-chips-multiple-container {
    border-radius: 8px !important;
    background-color: #F0F3F4 !important;
    height: 32px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border: 0px !important;
    outline: medium none !important;
    font-size: 14px !important;
    font-family: var(--font-family-1) !important;
    width: 100%;

    margin: 0;
    list-style-type: none;
    cursor: text;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.p-chips-input-token {
    flex: 1 1 auto;
    display: inline-flex;
    background-color: transparent;
    height: 20px !important;
}

.p-chips-input-token input {
    border: 0 none;
    outline: 0 none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    height: 12px;
}

.p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0rem 1rem !important;
    margin-right: 3px !important;
    background: var(--color-9) !important;
    color: #2076BD !important;
    border-color: #2076BD !important;
    border: solid;
    border-width: 1px;
    border-radius: 7px !important;
}

