.tabview-moded {
    .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        background: transparent;
        border-color: transparent;
        color: var(--color-1);
        border-bottom: solid 3px var(--color-1)
    }

    .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
        box-shadow: none;
    }

    .p-tabview-panels {
        border-left: none;
        border-right: none;
        border-bottom: none;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        padding-left: 0;
        padding-right: 0;
    }

    .p-tabview-nav li .p-tabview-nav-link,
    .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        border-color: transparent;
        background: transparent;
        color: var(--color-39);
    }
}