/* Open Sans */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 100 900;
    src: url('/assets/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 100 900;
    src: url('/assets/fonts/Open_Sans/OpenSans-Italic-VariableFont_wdth,wght.ttf') format('truetype');
}

/* Montserrat */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100 900;
    src: url('/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100 900;
    src: url('/assets/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
}

/* Roboto */
@font-face {
    font-family: "Roboto";
    font-weight: 100;
    font-style: normal;
    src: url('/assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-weight: 300;
    font-style: normal;
    src: url('/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-weight: 400;
    font-style: normal;
    src: url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-weight: 500;
    font-style: normal;
    src: url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-weight: 700;
    font-style: normal;
    src: url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-weight: 900;
    font-style: normal;
    src: url('/assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-weight: 100;
    font-style: italic;
    src: url('/assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-weight: 300;
    font-style: italic;
    src: url('/assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-weight: 400;
    font-style: italic;
    src: url('/assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-weight: 500;
    font-style: italic;
    src: url('/assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-weight: 700;
    font-style: italic;
    src: url('/assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-weight: 900;
    font-style: italic;
    src: url('/assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}

/* Material */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/materialicons/v142/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }