// Grays
$darkGray: #444343;
$normalGray: #666666;
$normalGrayHover: #E6E6E6;
$buttons: #B7B6B6;
$lightGrayActive: #D0D0D0;
$normalGrayActive: #D0D0D0;
$backgroundInputsGray: #F2F2F2;
$normalGrayDisabled: #E7E7E7;
$inputsGrayDisabled: #FFFFFF;

// Comun Use
$white: white;
$black: black;
$backgroundFramePrimary: #F0F3F4;

// Primary
$normalPrimary:#E90505;
$normalPrimaryHover:#D20505;
$normalPrimaryDisabled:#F48181;
$darkPrimary:#B10D0D;
$darkPrimaryHover:#9F0C0C;

// Secondary
$normalSecondary: #002D74;
$normalSecondaryHover: #002968;
$normalSecondaryDisabled: #7F95B9;
$lightNormalSecondary: #00458D;

// Tertiary
$normalTertiary: $lightGrayActive;
$normalTertiaryHover: $normalGrayHover;
$normalTertiaryDisabled: $normalGrayDisabled;
$backgroundTable: #EDF0F5;


// Quaternary
$normalQuaternary: #FFCD00;
$normalQuaternaryHover: #E6B900;


// State
$successful: #47BA31;
$error: #E1181E;
$Primary: #FFB800;
$information: #1E37B7;

